<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1000px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">Surrender/Sea Waybill Notice</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left col_3">
            <table class="tbl_list font_sm gridtype">
              <colgroup>
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in items" :key="'tbl_' + idx">
                  <td>{{ vo.blNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float_left text_center col_7 pl20" style="max-height:500px;overflow-y:auto">
            <vue-pdf-app
              style="height: 300px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>
    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import reportInfo from '@/api/rest/trans/reportInfo'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'SurrenderNoticePop',
  components: {
  vuePdfApp: () => import('vue-pdf-app'),
  WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
  PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
        type: Object,
        default: function () {
            return {}
        }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      items: [],
      userInfo: {},
      pdf: {},
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  computed: {
    ...rootComputed
  },
  //실시간적으로 변하는 곳에 사용
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.items !== undefined) {
        this.setItems(this.$props.parentInfo.items)
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      if (this.$props.parentInfo.items !== undefined) {
        this.setItems(this.$props.parentInfo.items)
      }

      commonPdf.commonInit()
    },
    async setItems (items) {
      let usrNo = this.auth.userId

      if (commonPdf.isNotPRD()) {
        if (this.$props.parentInfo.testUsrNo) {
          usrNo = this.$props.parentInfo.testUsrNo
        }
      }

      await reportInfo.getReportInfoUser('')
      .then(res => {
        this.userInfo = res.data
      })

      const logInfo = {
          logUserId: this.auth.userId,
          logCtrCd: this.memberDetail.userCtrCd,
          logPlcCd: this.memberDetail.bkgPlcCd
      }

      this.items = items
      this.pdf = await commonPdf.setSeawaybillNotice(this.items, this.userInfo.userEnam, logInfo)

      console.log('this.pdf @@@@', this.pdf)
    },
    fnDownlaod () {
      const items = this.items || []

      if (items.length === 0) {
        return
      }

      items.forEach(p => {
        commonPdf.download(p.pdfUrl, 'KMTC' + p.blNo + ' SeaWaybill.pdf')
      })
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    }
  }
}

</script>

<style>
body {
    min-width:100% !important;
}

</style>
